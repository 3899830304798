import React, { useState } from 'react';
import styles from './WalletCreationModal.module.css';
import { auth, submitAddressToFirestore } from "../../utils/firebase"
import { toast } from 'react-toastify';
import { IoMdClose } from 'react-icons/io';

interface PopupModalProps {
  isOpen: boolean;
  onClose: () => void;
  createdWalletAddress: string;
  seedPhrase: string;
}

const WalletCreationModal = ({ isOpen, seedPhrase, createdWalletAddress, onClose }: PopupModalProps) => {
  const [userInput, setUserInput] = useState('');
  const [error, setError] = useState('');

  if (!isOpen) return null;

  const handleInputChange = (e: any) => {
    setUserInput(e.target.value);
  };

  const linkWalletAddress = async () => {
    if (auth.currentUser?.uid) {
      try {
        await submitAddressToFirestore(auth.currentUser.uid, createdWalletAddress, seedPhrase);
      } catch (error) {
        setError('Failed to link wallet. Please try again.');
        toast.error('Failed to link wallet. Please try again.')
      }
    } else {
      setError('UID is not available.');
    }
  };

  const verifySeedPhrase = async () => {
    if (userInput.trim() === seedPhrase) {
      // Proceed with wallet creation logic save wallet to DB
      await linkWalletAddress()
      setUserInput('')
      toast.success("Successfully created and linked wallet")
      onClose(); // Close the modal
    } else {
      setError('The seed phrase does not match. Please try again.');
    }
  };

  return (
    <div className={styles.walletModal}>


      <div className={styles.walletModalContent}>
        <IoMdClose className={styles.closeIcon} onClick={onClose} />
        <h2>Your Wallet Details</h2>

        <p>Please write down the following seed phrase and keep it in a secure place. This phrase is crucial for accessing and recovering your wallet.</p>
        <div className={styles.seedPhrase}>{seedPhrase}</div>
        <p>Enter your seed phrase below to confirm you have written it down:</p>
        <input type="text" value={userInput} onChange={handleInputChange} />
        {error && <div className={styles.errorMessage}>{error}</div>}

        <h3>Your Wallet Address</h3>
        <p>This is your unique wallet address. Please copy it down as well:</p>
        <div className={styles.walletAddress}>{createdWalletAddress}</div>

        <button onClick={verifySeedPhrase}>Confirm Seed Phrase</button>
      </div>
    </div>
  );
}

export default WalletCreationModal;

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, getDoc, getDocs, collection, query, where, doc, setDoc, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { User } from "./types";
import { toast } from "react-toastify";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

//import .env variables

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDERID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);

// connectAuthEmulator(auth, "http://localhost:9099")
// connectFirestoreEmulator(db, '127.0.0.1', 8080);
// connectFunctionsEmulator(functions, 'localhost', 5001);


/**
 * gets the user saved wallet address if it exists
 *
 * @param {string} uid - The user's ID.
 * @returns {Promise<boolean>} A promise that resolves to the wallet address of the user if they have a saved wallet, and null otherwise.
 */
const getSavedWallet = async (uid: string ) => {
  // console.log("enter get saved wallet")
  // console.log(uid)
  const docRef = doc(db, `users/${uid}`);
  const docSnap = await getDoc(docRef);


  if (docSnap.exists()) {
    // console.log("Document data:", docSnap.data());
    let userData = docSnap.data() as User;
    let address = userData.walletAddress;
    return address;
  } else {
    // docSnap.data() will be undefined in this case
    // console.log("No such document!");
    return docSnap.data();
  }
}

/**
 * submits the user's wallet address to firestore
 *
 * @param {string} uid - The user's ID.
 * @param {string} address - The user's wallet address.
 * @returns {Promise<void>} A promise that resolves to void.
 */
const submitAddressToFirestore = async (uid: string, address: string, seedPhrase?: string) => {

  if (!auth.currentUser) {
    toast.error("You must be logged in to save your wallet address!");
    return;
  }

  const user: User = {
    id: uid,
    email: auth.currentUser.email,
    walletAddress: address.toLowerCase(),
  }
  if (seedPhrase) {
    user.seedPhrase = seedPhrase;
  }
  // Add a new document in collection "users"
  await setDoc(doc(db, `users/${uid}`), user);
}

const checkIfWalletExists = async (walletAddress: string) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ walletAddress, uid: auth.currentUser?.uid, email: auth.currentUser?.email })
  };

  try {
    const response = await fetch(process.env.REACT_APP_PRODUCTION_FIREBASE_FUNCTIONS_URL_CHECKIFWALLETEXISTS || "http://127.0.0.1:5001/xva-collective/us-central1/checkIfWalletExists", requestOptions);
    const data = await response.json();
    console.log(data)
    if (data.success) {
      if (data.exists) {
        toast.error(data.message, { autoClose: 15000 });
        toast.error("Please try again with a different wallet address or log in with the same account used for this wallet address", { delay: 2000, autoClose: 15000 });
        return true
      }
    } else {
      console.error('Server error unhanlded response');
      toast.error("Server error")
    }
  } catch (error: any) {
      // Getting the Error details.
      const message = error.message;
      console.error(error)
      toast.error(message)
  }
}

const getPayableAmount = async (currency: string, lots: number) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ currency, lots })
  };

  try {
    const response = await fetch(process.env.REACT_APP_PRODUCTION_FIREBASE_FUNCTIONS_URL_GETXVALOTSCOST || "http://127.0.0.1:5001/xva-collective/us-central1/getXvaLotsCost", requestOptions);
    const data = await response.json();
    console.log(data)
    return { currency: data.currency, amount: data.amount, basisPoints: data.basisPoints, decimals: data.decimals }
  }
  catch (error: any) {
    // Getting the Error details.
    const message = error.message;
    console.error(error)
    toast.error(message)
  }
}

export { auth, db, app, functions, getSavedWallet, submitAddressToFirestore, checkIfWalletExists, getPayableAmount };

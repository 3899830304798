import React, { useState } from 'react';
import { IoMdClose } from "react-icons/io"
import styles from "./popupEmailModal.module.css"
import { auth } from "../../utils/firebase"
import { sendSignInLinkToEmail, signInWithPopup, GoogleAuthProvider  } from "firebase/auth";
import { FaGoogle } from "react-icons/fa";
import { toast } from 'react-toastify';


interface PopupEmailModalProps {
  isOpen: boolean;
  onClose: () => void;
  email: string;
  setEmail: (newEmail: string) => void;
}

const PopupEmailModal: React.FC<PopupEmailModalProps> = ({ isOpen, onClose, email, setEmail }) => {
  const [emailSent, setEmailSent] = useState(false);

  // const sendEmail = async () => {
  //   const url = process.env.REACT_APP_NODE_ENV === 'production'
  //   ? (process.env.REACT_APP_PRODUCTION_URL || 'https://xva-collective.web.app')
  //   : 'http://localhost:3000/';
  //   const actionCodeSettings = {
  //     url,
  //     handleCodeInApp: true,
  //   };

  //   console.log(email)

  //   sendSignInLinkToEmail(auth, email, actionCodeSettings)
  //     .then(() => {

  //       window.localStorage.setItem('emailForSignIn', email);
  //       console.log(localStorage.getItem('emailForSignIn'))
  //       setEmailSent(true)
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //     });
  // }

  const loginWithGoogle = async () => {
    const provider = new GoogleAuthProvider()
    signInWithPopup(auth, provider)
    .then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential?.accessToken;
      // The signed-in user info.
      const user = result.user;
      console.log(user)
      onClose()
      // IdP data available using getAdditionalUserInfo(result)
      // ...
    }).catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.customData.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      toast.error(`Google Sign In Error: ${errorMessage}`)
      // ...
    });
  }


  if (!isOpen) {
    return null;
  }

  return (
    <div className={styles.overlay}>
      <div className={`${styles.modal} ${emailSent ? styles.verificationSent : ''}`}>
        <IoMdClose className={styles.closeIcon} onClick={onClose} />
        {emailSent ? (
          <>
            <h2>Check Your Email</h2>
            <p>Please check your email for a verification link to continue.</p>
          </>
        ) : (
          <>
            <h2>Welcome!</h2>
            <p>Enter your email to sign in</p>
            <p>We will send you an email with a link to sign up / sign in to your account with us</p>
            {/* <input
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              className={styles.emailInput}
              placeholder='Enter Your Email Address'
            />
            <button className={styles.signInLinkButton} onClick={sendEmail}>Send Sign-In Link</button> */}
            <button className={styles.googleSignInBtn} onClick={loginWithGoogle}>
              <FaGoogle className={styles.googleIcon}/>
              <span className={styles.buttonText}>Sign in with Google</span>
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default PopupEmailModal;
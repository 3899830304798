import React from "react";
import "./hero.css";

interface Props {
  refProp: React.RefObject<HTMLDivElement>;
  setIsCheckoutPopupOpen: (b: boolean) => void;
}

export default function Hero({ refProp, setIsCheckoutPopupOpen }: Props) {
  return (
    <div className="hero" id="about" ref={refProp}>
      <div className="hero1">
        <img
          className="fox"
          src={require("../../assets/XVA Fox 1.png")}
          alt=""
        />

        <div className="section-1">
          <div className="heading-1">$SPLRG UTILITY TOKENS</div>
          <div className="heading-2">
            <p>
              Buy and HODL?
              <br />
              That's so yesterday
            </p>

            <p>
              Buy. HODL. Use.
              <br />
              That's the future
            </p>

            <p>Buy a piece of tomorrow – today</p>
          </div>

          <br />
          <br />

          <div>
            <div className="heading-1">Time-limited Deal:</div>
            <p>
              Buy $SPLRG utility tokens at USD 0.08 each
              <br />
              Min spend 1◎; max spend 5◎
              <br />
              No vesting period
            </p>
          </div>
        </div>
      </div>

      <br />
      <br />
      <br />

      <div className="hero2">
        <div className="heading-big center">
          Exclusive Offer for Our Bored Ape Solana Club Friends!
        </div>
        <div className="section">
          <ul>
            <li>Extra 10% $SPLRG tokens with 1◎ $SPLRG purchased</li>
            <li>Extra 15% $SPLRG tokens with 3◎ $SPLRG purchased</li>
            <li>Extra 25% $SPLRG tokens with 5◎ of $SPLRG purchased</li>
          </ul>
          <br />
          <p>
            Remember to enter the promo code to unlock this exclusive $SPLRG
            offer!
          </p>

          <br />
          <button onClick={() => setIsCheckoutPopupOpen(true)}>
            BUY $SPLRG NOW
          </button>
        </div>
      </div>

      <br />
      <br />
      <br />

      <div className="hero2">
        <div className="heading-big center">Why $SPLRG</div>
        <div className="section">
          <p>
            Our mission is to flip the script on exclusivity,
            <br />
            jazz up blockchain utility,
            <br />
            and rewrite the playbook for luxury access.
          </p>

          <p>
            By tokenising real world assets into unique, tradable NFTs,
            <br />
            we are reshaping how luxury assets are collected.
            <br />
            Everyone can $SPLRG.
          </p>
        </div>

        <br />

        <div className="heading-big center">What is $SPLRG</div>
        <div className="section">
          <p>$SPLRG = utility tokens + real-world asset NFTS.</p>

          <p>
            $SPLRG will unlock NFTs that are backed by luxury assets, premium
            collectibles, one-of-a-kind experiences, and elite privileges.
          </p>
        </div>

        <div className="splrg-images">
          <img src={require("../../assets/splrg-tokens.png")} alt="" />
          <img src={require("../../assets/splrg-rwa-nfts.png")} alt="" />
        </div>
      </div>

      <br />
      <br />
      <br />

      <div className="hero2">
        <div className="heading-big center">What you get when you $SPLRG</div>
        <div className="section">
          The $SPLRG market offering will initially be divided into 3 categories
          assets, collectibles & experiences. Our mission is to only provide
          highly premium, limited edition, and customized Real World Assets for
          the $SPLRG customer.
        </div>
        <br />

        <div className="heading center">Luxury Assets.</div>
        <img src={require("../../assets/luxary-assets.png")} alt="" />
        <div className="section">
          With over $1.5 million worth of premium wines in our warehouse, we are
          now expanding our partnerships to include other luxury assets. To
          qualify for tokenization, these assets must have a well-documented
          price history and demonstrate yield potential. This approach extends
          beyond wines to include premium spirits, luxury bags, and high-end
          watches.
        </div>
        <br />

        <div className="heading center">Collectibles.</div>
        <img src={require("../../assets/collectables.png")} alt="" />
        <div className="section">
          We’re collaborating with renowned brands to craft exclusive, highly
          sought-after collectibles, while also developing our own line of
          $SPLRG-branded products. Collaboration is at the heart of what we do,
          spanning both the fashion industry and the broader Web3 space. By
          teaming up with luxury brands and key players in the Web3 community,
          we combine expertise and influence to create standout, one-of-a-kind
          products. These partnerships not only enhance the $SPLRG brand but
          also unlock new opportunities for growth and visibility.
        </div>
        <br />

        <div className="heading center">Experiences.</div>
        <img src={require("../../assets/experiences.png")} alt="" />
        <div className="section">
          We’re actively in talks with event companies, exclusive membership
          communities, and luxury hotel groups to curate unique, high-end
          experiences exclusively for the $SPLRG ecosystem. Our mission is to
          amplify the value of $SPLRG by offering extraordinary, life-enhancing
          opportunities that enrich and elevate the lives of our community
          members.
        </div>
      </div>

      <br />
      <br />
      <br />

      <div className="hero2">
        <div className="heading-big center">Key $SPLRG Collaboration</div>
        <div className="section">
          At{" "}
          <a
            href="https://x.com/search?q=$SPLRG&src=cashtag_click"
            target="_blank"
            rel="noreferrer"
          >
            $SPLRG
          </a>
          , we’re are also building some key partnerships.
          <br />
          We’re thrilled to announce a game-changing partnership with{" "}
          <a
            href="https://x.com/BoredApeSolClub"
            target="_blank"
            rel="noreferrer"
          >
            @BoredApeSolClub
          </a>
          , one of the most influential communities in the crypto space.
          <br />
          Add in{" "}
          <a href="https://x.com/vtopiaio" target="_blank" rel="noreferrer">
            @vtopiaio
          </a>
          , Solana’s first community-driven NFT Marketverse.
          <br />
          and{" "}
          <a href="https://x.com/theBroDAO" target="_blank" rel="noreferrer">
            @theBroDAO
          </a>
          , a curated group of investors, builders, & artists, built on
          community & integrity, highlighting web3 values & technology.
          <br />
          <br />
          Soon, you'll be trading these gems across crypto exchanges worldwide.
          Remember, in this Web3 world we call home,{" "}
          <b>luxury is the new currency!</b>
        </div>
      </div>

      <br />
      <br />
      <br />

      <div className="hero2">
        <div className="heading-big center">$SPLRG Tokenomics</div>
        <div className="section">
          <p>
            We aim for a market cap of 500 million $SPLRG tokens, with an
            initial release sale of 100 million tokens valued at US$5 million.
          </p>

          <p>
            The planned listing on XT exchange in Q4 2024 is expected to further
            enhance the token's visibility and liquidity.
          </p>

          <p>
            $SPLRG Utility Token is on the Solana Ecosystem, and it gives
            privilege access to our NFTs.
          </p>

          <p>
            We are now raising 5 million USD with the first release of 100
            million tokens at prices 0.03, 0.04, 0.08 and 0.11 to be listed on
            XT Exchange
            <br />
            0.03 USD. With a minimum of 10 thousand USD, half has already be
            taken up.
            <br />
            0.04 USD. With a minimum of 10 thousand USD is already reserved.
          </p>

          <p>
            Currently our tokens are being sold at 0.08 USD with a minimum of 1
            SOL and maximum of 5 SOL with no vesting period.
          </p>
        </div>

        <br />

        <img
          className="big-image"
          src={require("../../assets/tokenomics.png")}
          alt=""
        />

        <br />

        <div className="section">
          <p>
            For a quick summary of what $SPLRG is all about{" "}
            <a
              href="https://xva.asia/info1.pdf"
              target="_blank"
              rel="noreferrer"
            >
              click here
            </a>
            <br />
            For a more detailed White Paper on $SPLRG{" "}
            <a
              href="https://xva.asia/info2.pdf"
              target="_blank"
              rel="noreferrer"
            >
              click here
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";
import styles from "./popup.module.css";
import {
  auth,
  checkIfWalletExists,
  submitAddressToFirestore,
} from "../../utils/firebase";

import {
  WalletDisconnectButton,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import { useWallet } from "@solana/wallet-adapter-react";

import { generateMnemonic, mnemonicToSeedSync } from "@scure/bip39";
import { wordlist } from "@scure/bip39/wordlists/english";
import { HDKey } from "micro-ed25519-hdkey";
import { Keypair } from "@solana/web3.js";

interface PopupModalProps {
  isOpen: boolean;
  onClose: () => void;
  setCreatedWalletAddress: (address: string) => void;
  setSeedPhrase: (address: string) => void;
  setIsWalletCreationModalOpen: (bool: boolean) => void;
}

const PopupCreateOrInputWalletModal: React.FC<PopupModalProps> = ({
  isOpen,
  onClose,
  setCreatedWalletAddress,
  setSeedPhrase,
  setIsWalletCreationModalOpen,
}) => {
  const [hasWallet, setHasWallet] = useState(false);
  const [isLinking, setIsLinking] = useState(false);
  const [linkSuccess, setLinkSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const { publicKey } = useWallet();

  const linkWalletAddress = async () => {
    if (auth.currentUser && publicKey) {
      try {
        setIsLinking(true);
        const exists = await checkIfWalletExists(publicKey.toBase58());
        if (exists) {
          console.error("user wallet exists duplicate");
          setLinkSuccess(false);
          setIsLinking(false);
          return;
        }
        await submitAddressToFirestore(
          auth.currentUser.uid,
          publicKey.toBase58()
        );
        setLinkSuccess(true);
        setIsLinking(false);
      } catch (error) {
        setErrorMsg("Failed to link wallet. Please try again.");
        setIsLinking(false);
      }
    } else {
      setErrorMsg("UID is not available.");
    }
  };

  if (!isOpen) {
    return null;
  }

  const handleCloseSuccessMessage = () => {
    setLinkSuccess(false);
    onClose();
  };

  const handleLogout = async () => {
    await auth.signOut();
    setLinkSuccess(false);
    onClose();
  };

  const handleCreateWallet = async () => {
    try {
      const mnemonic = generateMnemonic(wordlist);
      const seed = mnemonicToSeedSync(mnemonic, "");
      const key = HDKey.fromMasterSeed(seed);
      const keypair = Keypair.fromSeed(
        key.derive("m/44'/501'/0'/0'").privateKey
      );

      setCreatedWalletAddress(keypair.publicKey.toBase58());
      setSeedPhrase(mnemonic);
      setIsWalletCreationModalOpen(true);
      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.overlay}>
      <div className={styles.modal}>
        <IoMdClose className={styles.closeIcon} onClick={onClose} />
        {!linkSuccess ? (
          <>
            <h2>Set Up Your Wallet</h2>
            <p>Hello @{auth.currentUser?.email || "..."}</p>
            {errorMsg && <p className={styles.errorMsg}>{errorMsg}</p>}
            <p>Do you have an existing crypto wallet?</p>
            <div className={styles.buttonGroup}>
              <button
                className={`${styles.button} ${hasWallet ? styles.active : ""}`}
                onClick={() => setHasWallet(true)}>
                Yes, I have a wallet
              </button>
              <button
                className={`${styles.button} ${
                  hasWallet === false ? styles.active : ""
                }`}
                onClick={() => setHasWallet(false)}>
                No, create a wallet for me
              </button>
            </div>
            {hasWallet && (
              <div>
                {/* <ConnectWallet
                  theme={"dark"}
                  switchToActiveChain={true}
                  modalSize={"wide"}
                  style={{ width: "100%" }}
                /> */}
                <WalletMultiButton style={{ width: "100%" }} />
                {publicKey && (
                  <button
                    onClick={linkWalletAddress}
                    className={styles.submitButton}
                    disabled={isLinking}>
                    {isLinking ? "Linking..." : "Link Wallet Address"}
                  </button>
                )}
                {/* <button onClick={disconnect}>disconnect</button> */}
              </div>
            )}
            {hasWallet === false && (
              <div>
                {/* Placeholder for wallet creation logic */}
                <button
                  className={styles.submitButton}
                  onClick={handleCreateWallet}>
                  Create Wallet
                </button>
              </div>
            )}
            <button
              className={styles.submitButton}
              style={{ background: "#d32f2f" }}
              onClick={handleLogout}>
              Logout
            </button>
          </>
        ) : (
          <>
            <h2>Success!</h2>
            <p>
              Your wallet address has been successfully linked to your account.
            </p>
            <button
              className={styles.submitButton}
              onClick={handleCloseSuccessMessage}>
              Close
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default PopupCreateOrInputWalletModal;

import React from 'react';
import styles from './SuccessfulPaymentModal.module.css';

interface PopupModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const SuccessfulPaymentModal: React.FC<PopupModalProps> = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  const handleClose = () => {
    onClose()

    const urlObject = new URL(window.location.href);
    const origin = urlObject.origin;
    const pathname = urlObject.pathname;
    const newUrl = origin + pathname;

    window.location.href = newUrl;
  }

  return (
    <div className={styles.successModal}>
      <div className={styles.successModalContent}>
        <h2>Payment Successful!</h2>
        <p>Your payment for the product has been successfully processed.</p>
        <button onClick={handleClose}>Close</button>
      </div>
    </div>
  );
};

export default SuccessfulPaymentModal;

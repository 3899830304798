import { useEffect, useState } from "react";
import "./app.css";
import Navbar from "./components/navbar/Navbar";
import Launchcountdown from "./components/launchcountdown/Launchcountdown";
import Mintflow from "./components/mintflow/Mintflow";
import Buytoken from "./components/buytoken/Buytoken";
import WhyXVAToken from "./components/whyxvatoken/WhyXVAToken";
import Team from "./components/team/Team";
import Footer from "./components/footer/Footer";
import HowToBuy from "./components/howtobuy/HowToBuy";
import { auth, getSavedWallet } from "./utils/firebase";
import {
  isSignInWithEmailLink,
  signInWithEmailLink,
  User,
  onAuthStateChanged,
} from "firebase/auth";
import CopyWrite from "./components/copywrite/CopyWrite";
import PopupEmailModal from "./components/popupEmail/PopupEmailModal";
import PopupCreateOrInputWalletModal from "./components/popupCreateOrInputWalletModal/PopupCreateOrInputWalletModal";
import CheckoutModal from "./components/checkoutModal/CheckoutModal";
import SuccessfulPaymentModal from "./components/SuccessfulPaymentModal/SuccessfulPaymentModal";
import CancelPaymentModal from "./components/cancelPaymentModal/CancelPaymentModal";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WalletCreationModal from "./components/walletCreationModal/WalletCreationModal";
import Hero from "./components/hero/Hero";
import Hero2 from "./components/hero2/Hero2";
import Table from "./components/table/Table";
import Hero3 from "./components/hero3/Hero3";
import { useRef } from "react";

require("@solana/wallet-adapter-react-ui/styles.css");

function App() {
  const [email, setEmail] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isSecondPopupOpen, setIsSecondPopupOpen] = useState(false);
  const [isSuccessPaymentOpen, setIsSuccessPaymentOpen] = useState(false);
  const [isCancelPaymentOpen, setIsCancelPaymentOpen] = useState(false);
  const [isCheckoutPopupOpen, setIsCheckoutPopupOpen] = useState(false);
  const [quantity, setQuantity] = useState("1");
  const [total, setTotal] = useState(0);
  const [isWalletCreationModalOpen, setIsWalletCreationModalOpen] =
    useState(false);
  const [seedPhrase, setSeedPhrase] = useState("");
  const [createdWalletAddress, setCreatedWalletAddress] = useState("");

  const tokenPrice = 0.03;

  useEffect(() => {
    // listening if user is signing in with email link
    if (isSignInWithEmailLink(auth, window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let emailFromStorage = localStorage.getItem("emailForSignIn") || "";
      console.log("EMAILFRMSTORAGE", emailFromStorage);
      if (!emailFromStorage) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        // emailFromStorage = window.prompt('Please provide your email for confirmation');
      }
      // The client SDK will parse the code from the link for you.
      signInWithEmailLink(auth, emailFromStorage, window.location.href)
        .then((result) => {
          // Clear email from storage.
          // window.localStorage.removeItem('emailForSignIn');
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
          console.log(result);
        })
        .catch((error) => {
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
          console.log(error);
          console.log("EMAIL", email);
        });
    }

    // listen successful payment
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setIsSuccessPaymentOpen(true);
    }

    if (query.get("canceled")) {
      setIsCancelPaymentOpen(true);
    }
    console.log(walletAddress);
  }, []);

  onAuthStateChanged(auth, async (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/auth.user
      const uid = user.uid;
      let address = await getSavedWallet(uid);
      // console.log(address);
      if (address) {
        setWalletAddress(address.toString());
      } else {
        setIsSecondPopupOpen(true);
      }
      // ...
    } else {
      // User is signed out
      // ...
    }
  });

  const buyTokenRef = useRef(null);
  const teamRef = useRef(null);
  const heroRef = useRef(null);
  const topRef = useRef(null);

  const scrollTo = (ref: any) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const bg_display = (
    <>
      <div className="bg-top">
        <img
          className=""
          src={require(`./XVA_TokenPage_UIAssets/Website_BG_Section1_optimized.gif`)}
          alt=""
        />
      </div>

      {/* <div className="bg-middle">
        <img
          className=""
          src={require("./XVA_TokenPage_UIAssets/Website_BG_Section2_shortened.gif")}
          alt=""
        />
      </div> */}
      <div className="bg-bottom">
        <img
          className=""
          src={require("./XVA_TokenPage_UIAssets/Website_BG_Section3_optimized.gif")}
          alt=""
        />
      </div>
    </>
  );

  return (
    <div className="app">
      <div ref={topRef}></div>
      <Navbar
        onNavLinkClick={(ref) => {
          scrollTo(ref);
        }}
        onStart={() => {
          setIsPopupOpen(true);
        }}
        onLinkWallet={() => {
          setIsSecondPopupOpen(true);
        }}
        teamRef={teamRef}
        buyTokenRef={buyTokenRef}
        aboutRef={heroRef}
        topRef={topRef}
      />

      {bg_display}

      <div className="wrapper">
        <ToastContainer theme="dark" />
        <PopupEmailModal
          isOpen={isPopupOpen}
          onClose={() => {
            setIsPopupOpen(false);
          }}
          email={email}
          setEmail={setEmail}
        />
        <PopupCreateOrInputWalletModal
          isOpen={isSecondPopupOpen}
          onClose={() => {
            setIsSecondPopupOpen(false);
          }}
          setCreatedWalletAddress={setCreatedWalletAddress}
          setSeedPhrase={setSeedPhrase}
          setIsWalletCreationModalOpen={setIsWalletCreationModalOpen}
        />
        <WalletCreationModal
          isOpen={isWalletCreationModalOpen}
          onClose={() => {
            setIsWalletCreationModalOpen(false);
            setIsSecondPopupOpen(false);
          }}
          seedPhrase={seedPhrase}
          createdWalletAddress={createdWalletAddress}
        />
        <CheckoutModal
          isOpen={isCheckoutPopupOpen}
          onClose={() => setIsCheckoutPopupOpen(false)}
          email={auth.currentUser?.email || null}
          walletAddress={walletAddress}
          tokenPrice={tokenPrice}
          quantity={quantity}
          total={total}
          setQuantity={setQuantity}
          setTotal={setTotal}
        />
        <SuccessfulPaymentModal
          isOpen={isSuccessPaymentOpen}
          onClose={() => setIsSuccessPaymentOpen(false)}
        />
        <CancelPaymentModal
          isOpen={isCancelPaymentOpen}
          onClose={() => setIsCancelPaymentOpen(false)}
        />
      </div>

      <div className="container">
        <Hero
          refProp={heroRef}
          setIsCheckoutPopupOpen={setIsCheckoutPopupOpen}
        />
        {/* <Hero refProp={heroRef} />
        <WhyXVAToken />
        <Hero2 />
        <Hero3 />
        <Table refProp={buyTokenRef} />
        <Buytoken
          userWalletAddress={walletAddress}
          tokenPrice={tokenPrice}
          refProp={teamRef}
          quantity={quantity}
          total={total}
          setQuantity={setQuantity}
          setTotal={setTotal}
          setIsCheckoutPopupOpen={setIsCheckoutPopupOpen}
        />
        <Team /> */}

        <Footer />
      </div>
    </div>
  );
}

export default App;

// connect wallet pop up
// 1) connect wallet button
// 2) create wallet button with email to link to wallet

// have crypto wallet
// 1) connect wallet
// 2) press buy button
// 3) check if wallet pubkey is in db
// 4) if not, pop email auth stating this wallet will be linked to this email
// 5) store the wallet pubkey with email in db

// no wallet
// 1) press create wallet button
// 2) create wallet and store wallet pubkey with email in db
// 3) display wallet pubkey, seedphrase and allow user to download wallet file

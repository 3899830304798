import React, { useState, useMemo } from "react";
import { CgProfile } from "react-icons/cg";
import "./navbar.css";
import SalesProgressBar from "./SalesProgressBar";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";

import { auth, getSavedWallet } from "../../utils/firebase";
import { useWallet } from "@solana/wallet-adapter-react";

interface PopupModalProps {
  onStart: () => void;
  onLinkWallet: () => void;
  onNavLinkClick: (ref: React.RefObject<HTMLDivElement>) => void;
  teamRef: React.RefObject<HTMLDivElement>;
  buyTokenRef: React.RefObject<HTMLDivElement>;
  aboutRef: React.RefObject<HTMLDivElement>;
  topRef: React.RefObject<HTMLDivElement>;
}

const Navbar: React.FC<PopupModalProps> = ({
  topRef,
  buyTokenRef,
  teamRef,
  aboutRef,
  onStart,
  onLinkWallet,
  onNavLinkClick,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [walletAddressDisplay, setWalletAddressDisplay] =
    useState("Not yet set");

  const toggleDropdown = async () => {
    if (auth.currentUser) {
      const uid = auth.currentUser.uid;
      getSavedWallet(uid).then((add) => {
        let address = add?.toString();
        console.log(address);
        if (address) {
          const truncatedAddress =
            address.slice(0, 7) + "..." + address.slice(-5);
          setWalletAddressDisplay(truncatedAddress);
        } else {
          setWalletAddressDisplay("Not yet set");
        }
      });
      // ...
    }
    setShowDropdown(!showDropdown);
  };

  const handleLogout = async () => {
    await auth.signOut();
    setWalletAddressDisplay("Not yet set");
    setShowDropdown(false);
  };

  const handleLinkWallet = () => {
    onLinkWallet();
    setShowDropdown(false);
  };

  return (
    <>
      <div className="navbar" id="navbar">
        <a className="logo" onClick={() => onNavLinkClick(topRef)}>
          <img
            src={require("../../XVA_TokenPage_UIAssets/XVA_Icons/XVALogoTopNAV.png")}
            alt=""
          />
        </a>
        {/* <div className="links">
          <a onClick={() => onNavLinkClick(buyTokenRef)} className="link">Buy Token</a>
          <a onClick={() => onNavLinkClick(aboutRef)} className="link">About</a>
          <a onClick={() => onNavLinkClick(teamRef)} className="link">Team</a>
          {auth.currentUser ? (
            <div className="user-info">
              <CgProfile
                className="user-icon"
                onClick={toggleDropdown}
              />
              {showDropdown && (
                <div className="dropdown-menu">
                  <p>Email: {auth.currentUser?.email}</p>
                  <p>Wallet: {walletAddressDisplay}</p>
                  <button className="link-wallet-button" onClick={handleLinkWallet}>{(walletAddressDisplay === "Not yet set") ? "Link a wallet" : "Link a different wallet address"}</button>
                  <button className="logout-button" onClick={handleLogout}>Logout</button>
                </div>
              )}
            </div>
          ) : (<button className="get-started" onClick={onStart}><img src={require('../../XVA_TokenPage_UIAssets/XVA_FigmaAssets/wallet icon.png')} alt="" />Login</button>)
          }
        </div> */}

        <WalletMultiButton>
          {useWallet().publicKey ? "" : "Connect Wallet"}
        </WalletMultiButton>
      </div>
      {/* <div className='belowNavbarWrapper'>
        <div className="seedround">
          Seed round 1 ongoing...
        </div>
        <SalesProgressBar numerator={10} denominator={100} />
      </div> */}
    </>
  );
};

export default Navbar;

export type ChainType = "mainnet" | "testnet";
export const CHAIN: ChainType = "testnet";

export const MAINNET_RPC_URL = "https://binance.llamarpc.com";
export const TESTNET_RPC_URL =
  "https://data-seed-prebsc-1-s1.bnbchain.org:8545";
export const RPC_URL =
  (CHAIN as ChainType) == "mainnet" ? MAINNET_RPC_URL : TESTNET_RPC_URL;

export const CONTRACT_ADDRESSESS: { [key: string]: { [key: string]: string } } =
  {
    USDC: {
      mainnet: "0x8965349fb649a33a30cbfda057d8ec2c48abe2a2",
      testnet: "0x16227D60f7a0e586C66B005219dfc887D13C9531",
    },
    BUSD: {
      mainnet: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
      testnet: "0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee",
    },
    BETH: {
      mainnet: "0x2170ed0880ac9a755fd29b2688956bd959f933f8",
      testnet: "0xd66c6B4F0be8CE5b39D52E0Fd1344c389929B378",
    },
    MYUSD: {
      testnet: "0xe6A696b0DFe172077af8599D241914ea3c6d4A4e",
    },
  };

// export const XVA_LOT_SIZE = 30_000;
// export const XVA_LOT_PRICE_USD = 900;
export const XVA_LOT_SIZE = 1000;
export const XVA_LOT_PRICE_USD = 30;
// export const TREASURY_ADDRESS = "0x10eeA08C814fFCb4Ef7948b2D2feDfD32bdf9F5F";
export const TREASURY_ADDRESS = "8vnQUfp552HhhZccuwznzBH9TdbLRu97PAe2Kki9ZuYw";
export const USDC_CONTRACT_ADDRESSES = CONTRACT_ADDRESSESS.USDC[CHAIN];
export const BUSD_CONTRACT_ADDRESSES = CONTRACT_ADDRESSESS.BUSD[CHAIN];
export const BETH_CONTRACT_ADDRESSES = CONTRACT_ADDRESSESS.BETH[CHAIN];
export const MYUSD_CONTRACT_ADDRESSES = CONTRACT_ADDRESSESS.MYUSD[CHAIN];

export function convertXvaToCurrency(xvaAmount: number, currency: string) {
  // Conversion rates
  const xvaToUsd = 0.03;
  const bnbToUsd = 228.42;
  const bethToUsd = 2202.22;
  const usdcToUsd = 1; // Equivalent to USD
  const busdToUsd = 1; // Equivalent to USD
  const myusdToUsd = 1; // Equivalent to USD

  // Convert xvaTokens to USD
  let usdAmount = xvaAmount * xvaToUsd;

  // Convert USD to the specified currency
  switch (currency) {
    case "BNB":
      return usdAmount / bnbToUsd;
    case "BETH":
      return usdAmount / bethToUsd;
    case "USDC":
      return usdAmount / usdcToUsd;
    case "BUSD":
      return usdAmount / busdToUsd;
    case "MYUSD":
      return usdAmount / myusdToUsd;
    default:
      throw new Error(
        "Unsupported currency. Please choose from 'BUSD', 'USDC', 'BNB', or 'BETH'."
      );
  }
}

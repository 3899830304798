import React from "react";
import "./footer.css";

export default function Footer() {
  return (
    <div className="footer">
      <div className="top">
        <div className="heading"> Connect with us</div>
        <img
          className="arrow1"
          src={require("../../XVA_TokenPage_UIAssets/XVA_FigmaAssets/ArrowLeft.png")}
          alt=""
        />
        <img
          className="arrow2"
          src={require("../../assets/Top Border.png")}
          alt=""
        />
      </div>
      <div className="bottom">
        <div className="text">
          Follow us for updates and launch of our NFT Marketplace with
          1.4million with tokenised wines and new luxury asset-backed NFTs
        </div>
        <div className="socials-faq">
          <div className="socials">
            <a href="https://discord.gg/cv6HHe4CgU">
              <img
                src={require("../../XVA_TokenPage_UIAssets/XVA_Icons/Social Media Logo - Discord.png")}
                alt=""
              />
            </a>
            <a href="https://www.instagram.com/xva_collective">
              <img
                src={require("../../XVA_TokenPage_UIAssets/XVA_Icons/Social Media Logo - Instagram.png")}
                alt=""
              />
            </a>
            <a href="https://www.linkedin.com/company/xva-collective/?originalSubdomain=sg">
              <img
                src={require("../../XVA_TokenPage_UIAssets/XVA_Icons/Social Media Logo - LinkedIn.png")}
                alt=""
              />
            </a>
            <a href="https://x.com/xva_collective">
              <img
                src={require("../../XVA_TokenPage_UIAssets/XVA_Icons/Social Media Logo - X.png")}
                alt=""
              />
            </a>
          </div>
          {/* <a href=""><img src={require('../../XVA_TokenPage_UIAssets/XVA_Icons/Social Media Logo - X.png')} alt="" /></a> */}
        </div>
      </div>

      <img
        className="arrow1"
        src={require("../../XVA_TokenPage_UIAssets/XVA_FigmaAssets/ArrowLeft.png")}
        alt=""
      />
      <img
        className="arrow2"
        src={require("../../assets/Top Border.png")}
        alt=""
      />
      <div className="rights">
        © 2024 XVA COLLECTIVE PTE LTD - ALL RIGHTS RESERVED.
      </div>
    </div>
  );
}

{
  /* 
<div className="address-socials-faq">
          <div className="address">
            <div className="name">
              XVA Collective Pte Ltd
            </div>
            <p>328 North Bridge Rd, #02-20 Raffles Hotel Arcade, Singapore 188719.</p>
          </div>
          <div className="socials-faq">
            <div className="socials">
              <a href=""><img src={require('../../XVA_TokenPage_UIAssets/XVA_Icons/Social Media Logo - Facebook.png')} alt="" /></a>
              <a href=""><img src={require('../../XVA_TokenPage_UIAssets/XVA_Icons/Social Media Logo - Instagram.png')} alt="" /></a>
              <a href=""><img src={require('../../XVA_TokenPage_UIAssets/XVA_Icons/Social Media Logo - LinkedIn.png')} alt="" /></a>
              <a href=""><img src={require('../../XVA_TokenPage_UIAssets/XVA_Icons/Social Media Logo - X.png')} alt="" /></a>
            </div>
            <a href=""><img src={require('../../XVA_TokenPage_UIAssets/XVA_Icons/Social Media Logo - X.png')} alt="" /></a>
          </div>
        </div>
      </div>

      <div className="rights">
        © 2022 XVA COLLECTIVE PTE LTD - ALL RIGHTS RESERVED.
      </div> */
}
